<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">培训档案</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div class="searchboxItem ci-full">
              <span title="班级信息" class="itemLabel">班级信息:</span>
              <el-select v-model="searchData.projectId" placeholder="请选择班级" remote size="small" clearable
                :remote-method="superProjectSelect" @visible-change="clearProjectSearchModel">
                <template>
                  <div class="select-header">
                    <span style=" color: #8492a6; float: left; font-size: 13px; width: 150px;">班级编号</span>
                    <span
                      style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 250px;">班级名称</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="searchInputForm.projectCode" v-on:input="superProjectSelect" type="text"
                      size="small" placeholder="班级编号" clearable style="width: 150px" />
                    <el-input v-model="searchInputForm.projectName" v-on:input="superProjectSelect" type="text"
                      size="small" placeholder="请输入班级名称" clearable style="margin-left: 50px; width: 250px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName"
                  :value="item.projectId">
                  <span style="width: 150px; font-size: 13px">{{ item.projectCode }}</span>
                  <span style="width: 250px; margin-left: 50px; font-size: 13px">{{ item.projectName }}</span>
                </el-option>
              </el-select>
            </div>
            <div class="searchboxItem ci-full">
              <span title="学员信息" class="itemLabel">学员信息:</span>
              <el-select v-model="searchData.userId" placeholder="请选择学员" remote size="small" clearable
                :remote-method="superUserSelect" @visible-change="clearUserSearchModel">
                <template>
                  <div class="select-header">
                    <span style=" color: #8492a6; float: left; font-size: 13px; width: 100px;">学员姓名</span>
                    <span
                      style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 140px;">身份证号码</span>
                    <span
                      style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 100px;">学员电话</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="searchInputForm.userName" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="学员姓名" clearable style="width: 100px" />
                    <el-input v-model="searchInputForm.idcard" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="身份证号码" clearable style="margin-left: 50px; width: 140px" />
                    <el-input v-model="searchInputForm.mobile" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="学员电话" clearable style="margin-left: 50px; width: 100px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in userList" :key="index" :label="item.userName" :value="item.userId">
                  <span style="width: 100px; font-size: 13px">{{ item.userName }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.idcard }}</span>
                  <span style="width: 100px; margin-left: 50px; font-size: 13px">{{ item.mobile }}</span>
                </el-option>
              </el-select>
            </div>
            <div class="searchboxItem ci-full">
              <span title="结业时间" class="itemLabel">结业时间:</span>
              <el-date-picker @change="dataPickerChange" v-model="searchData.graduation" size="small"
                value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData(1,true)">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="showDocsAllDialog()">下载档案</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              row-key="userId" @selection-change="handleSelectionChange" :header-cell-style="tableHeader" stripe>
              <el-table-column type="selection" align="center" reserve-selection />
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" minWidth="180" />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" minWidth="180" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" minWidth="100" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" minWidth="150" />
              <el-table-column label="联系电话" align="left" show-overflow-tooltip prop="mobile" minWidth="100" />
              <el-table-column label="认证状态" align="left" show-overflow-tooltip prop="authentication" minWidth="100">
                <template slot-scope="{row}">
                  {{ $setDictionary('AUTHENTICATION', row.authentication) }}
                </template>
              </el-table-column>
              <el-table-column label="课程名称" align="left" show-overflow-tooltip prop="courseName" minWidth="220" />
              <el-table-column label="学时学习状态" align="left" show-overflow-tooltip prop="completeState" minWidth="100">
                <template slot-scope="{row}">
                  {{ completeStateMap[row.completeState] }}
                </template>
              </el-table-column>
              <el-table-column label="是否通过考试" align="left" show-overflow-tooltip prop="paperState" minWidth="100">
                <template slot-scope="{row}">
                  {{ row.paperState == '1' ? '是' : row.paperState === null ? '--' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="考试成绩" align="left" show-overflow-tooltip prop="paperScore" minWidth="100" />
              <el-table-column label="是否结业" align="left" show-overflow-tooltip prop="graduationState" minWidth="100">
                <template slot-scope="{row}">
                  {{ row.graduationState ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="结业时间" align="left" show-overflow-tooltip prop="graduationTime" minWidth="150" />
              <el-table-column label="操作" align="center" width="140px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" size="mini" @click="showDocsDialog(scope.row)">下载档案</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog title="培训档案选择" :visible.sync="docsDialogVisible" v-if="docsDialogVisible" width="50%" top="5%" :center="true"
      :before-close="closeDocsDialog">
      <div>
        <div class="ovy-a">
          <div class="downloadStyle">
            <el-form ref="docsFormnew" :model="docsFormnew" class="docsDialog" v-show="danganList.length">
              <el-form-item v-for="(val, key) in danganList" :key="key">
                <el-checkbox v-model="docsFormnew[val.dictCode]">{{
                val.dictValue
              }}</el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <el-checkbox v-model="agreeState" style="margin: 0 3%">
            <span style="color: red !important">
              根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，
              不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。我已阅读并同意上述内容。
            </span>
          </el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center">
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "trainingManagement/TrainingRecords",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  doNotInit: true,
  data () {
    return {
      // 学员列表
      userList: [{}],
      // 班级列表
      projectList: [{}],
      danganList: [],
      docsFormnew: {},
      searchData: {
        userId: "",//学员id
        projectId: "",//班级id
        graduation: [],//结业时间
      },
      // 查询表单输入的内容
      searchInputForm: {
        userName: "",//学员姓名
        idcard: "",//身份证号
        mobile: "",//手机号
        projectName: "",//班级名称
        projectCode: "",//班级编号
      },
      // 下载相关
      agreeState: false, //弹框是否同意
      selectedItem: {},
      docsDialogVisible: false,
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      completeStateMap: {
        '10': '未学习',
        '20': '学习中',
        '30': '已完成',
        '40': '未完成',
      },
      multipleSelection: []
    };
  },
  created () {
    this.superProjectSelect();
  },
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted () {
  },

  methods: {
    handleSelectionChange (val) {
      console.log(val);
      this.multipleSelection = val;
    },
    // 搜索学员
    superUserSelect () {
      const { searchInputForm, searchData } = this;
      this.$post("/biz/user/companyUserSelect", {
        pageNum: 1, //每次都只要第一页
        pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
        ...searchInputForm,
        projectId: searchData.projectId,
      }, 3000, false).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    // 清除学员搜索信息
    clearUserSearchModel (e) {
      const { searchInputForm } = this;
      if (e) {
        searchInputForm.userName = "";
        searchInputForm.idcard = "";
        searchInputForm.mobile = "";
        this.superUserSelect();
      }
    },
    // 搜索班级
    superProjectSelect () {
      const { searchInputForm, searchData } = this;
      this.$post("/biz/project/superSelectProject", {
        pageNum: 1, //每次都只要第一页
        pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
        projectName: searchInputForm.projectName,
        projectCode: searchInputForm.projectCode,
        userId: searchData.userId,
      }, 3000, false).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    // 清除班级搜索信息
    clearProjectSearchModel (e) {
      const { searchInputForm } = this;
      if (e) {
        searchInputForm.projectName = "";
        searchInputForm.projectCode = "";
        this.superProjectSelect();
      }
    },
    //  取消下载
    closeDocsDialog () {
      this.docsDialogVisible = false;
      this.agreeState = false;
      for (let key in this.docsFormnew) {
        this.docsFormnew[key] = false;
      }
    },
    // 下载培训档案
    showDocsDialog (raw) {
      this.docsDialogVisible = true;
      this.selectedItem.id = raw.projectId;
      this.getList(raw.projectId);
      this.selectedItem.name = raw.projectName;
      this.selectedItem.code = raw.projectCode;
      this.selectedItem.userIds = [raw.userId];
    },
    // 批量下载培训档案
    showDocsAllDialog () {
      if (this.multipleSelection.length === 0) {
        this.$message.error("请至少勾选一项");
        return;
      }
      if (this.multipleSelection.findIndex(e=>e.projectId!==this.multipleSelection[0].projectId)!==-1) {
        this.$message.error("请选择相同班级打印");
        return;
      }
      this.docsDialogVisible = true;
      this.selectedItem.id = this.multipleSelection[0].projectId;
      this.getList(this.multipleSelection[0].projectId);
      this.selectedItem.name = this.multipleSelection[0].projectName;
      this.selectedItem.code = this.multipleSelection[0].projectCode;
      this.selectedItem.userIds = this.multipleSelection.map(e=>e.userId);
    },
    getList (id) {
      this.$post("/run/project/user/archives", { projectId: id }).then((res) => {
        this.danganList = res.data;
      });
    },
    // 下载档案
    confirmDocsDialog () {
      // return;
      if (!this.agreeState) {
        this.$message({
          type: "warning",
          message: "请先阅读提示内容并同意",
        });
        return;
      }
      let params = {
        fileDTOS: [],
      };
      for (let i in this.docsFormnew) {
        if (this.docsFormnew[i]) {
          params.fileDTOS.push({
            fileType: i,
            fileName:
              this.selectedItem.name +
              "-" +
              this.danganList.find((el) => el.dictCode == i).dictValue,
            className: className,
            param: {
              projectId: this.selectedItem.id,
            },
            personage:true,
            userIds:this.selectedItem.userIds
          });
        }
      }
      if (!params.fileDTOS.length) {
        this.$message.error("请至少选择一项进行打印");
        return;
      }
      // if (!params.fileDTOSAll.length) {
      //   this.$message.error("请至少选择一项进行打印");
      //   return;
      // }

      this.$post("/sys/download/asyncDownloadFile", params).then((re) => {
        if (re.status == "0") {
          let list = re.data;
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
          this.closeDocsDialog();
        } else {
          this.$message.error(re.message);
        }
      });
    },
    getData (pageNum = 1,isTap = false) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // if (!this.searchData.projectId&&!this.searchData.userId) {
      //   this.$message.error('请先选择班级或学员！')
      //   return
      // }
      if(isTap){
        this.$refs.multipleTable.clearSelection();
      }
      if (this.searchData.projectId) {
        params.projectId = this.searchData.projectId;
      }
      if (this.searchData.userId) {
        params.userId = this.searchData.userId
      }
      if (this.searchData.graduation?.length) {
        params.graduationStartDate = this.searchData.graduation[0]
        params.graduationEndDate = this.searchData.graduation[1]
      }
      
      this.doFetch({
        url: "/biz/projectUser/archive/download/pageList",
        params,
        pageNum,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}

.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}

.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}

.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
